import { useAuth0, User } from '@auth0/auth0-react';
import { AddLink, PersonAddAlt1, RocketLaunch } from '@mui/icons-material';
import {
  Alert,
  AlertColor,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import {
  Test_Tech_Services_Resources,
  TestTrialCandidatePartialFragment,
} from '../../../@generated/types';
import LearningResourcesView from '../../../components/LearningResourcesView';
import { EAssessmentType } from '../../../constants/assessment';
import { useBilling } from '../../../hooks/useBilling';
import { getUserHash, isPersonal } from '../../../utils/auth';
import { setUserHashInAssessmentDescription } from '../../AssessmentPage/utils';
import CreateDialog from '../../CandidatesPage/CandidatesPageList/CreateDialog';
import InviteDialog from '../../CandidatesPage/CandidatesPageList/InviteDialog';
import {
  ICandidate,
  ISendTestTrailRequestPayload,
  sendTestTrialInvitation,
} from '../../CandidatesPage/utils';
import CreateInvitationLinkDialog from '../../InvitationLinksPage/CreateInvitationLinkDialog';
import { IAssessmentLinkResponse } from '../../InvitationLinksPage/data';
import { ESettingsTabs } from '../../SettingsPage/data';
import { ICourseDescriptionProps } from '../interfaces';
import InvitationLink from './InvitationLink';
import { styleIcon, useHandleLaunchTest } from './data';

const CourseDescription: FC<ICourseDescriptionProps> = ({
  testId,
  testType,
  title,
  description,
  descriptionLong,
  testResources,
  image,
}) => {
  const theme = useTheme();
  const { canAccessProFeatures, hasNoAccess } = useBilling();
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [showCreateLinkDialog, setShowLinkCreateDialog] =
    useState<boolean>(false);
  const [showInviteCandidate, setShowInviteCandidate] =
    useState<boolean>(false);
  const [inviteUser, setInviteUser] = useState<
    User | TestTrialCandidatePartialFragment | null
  >(null);
  const [link, setLink] = useState<boolean>(false);
  const [InvitationLinkURL, setInvitationLink] = useState<string>('');
  const { getAccessTokenSilently, user } = useAuth0();
  const userHash = getUserHash(user);
  const handleLaunchTest = useHandleLaunchTest();

  const hideSandboxComponents = testType === EAssessmentType.SANDBOX;

  const handleNewInvitationLinkDialog = () => {
    setShowLinkCreateDialog(true);
  };
  const handleInviteCandidate = () => {
    setShowInviteCandidate(true);
  };
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  const handleCreateLinkDialogClose = () => {
    setShowLinkCreateDialog(false);
  };
  const handleLinkError = (error: string) => {
    handleCreateLinkDialogClose();
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
  };
  const handleCreateDialogClose = () => {
    setShowInviteCandidate(false);
  };
  const handleSuccess = (message: string) => {
    setShowSnackbar(true);
    setSeverity('success');
    setMessage(message);
  };
  const handleEditSuccess = () => {
    handleCreateLinkDialogClose();
    setShowSnackbar(true);
    setSeverity('success');
    setMessage('Invitation link updated successfully!');
  };
  const handleError = (error: string) => {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
  };

  const sendTestInvitationMutation = useMutation(
    async (payload: ISendTestTrailRequestPayload) => {
      const token = await getAccessTokenSilently();
      return await sendTestTrialInvitation(token, payload);
    },
    {
      onSuccess: (data) => {
        handleSuccess('Invitation sent successfully!');
      },
      onError: (error) => {
        handleError(`Something went wrong. Please try again.`);
      },
    }
  );

  const handleIvitationSuccess = ({
    message,
    'invitation-link': invitationLink,
  }: IAssessmentLinkResponse) => {
    setInvitationLink(invitationLink);
    handleInvitationOpen();
    handleCreateLinkDialogClose();
    setShowSnackbar(true);
    setSeverity('success');
    setMessage(message);
  };

  const handleCreateDialogSuccess = (candidate: ICandidate) => {
    const userExists = candidate.user_exists;
    setShowInviteCandidate(false);
    if (!userExists) {
      setInviteUser(candidate);
      setShowSnackbar(true);
      setSeverity('success');
      setMessage('Candidate created successfully!');
    } else {
      sendTestInvitationMutation.mutate({
        companyName: candidate.app_metadata.company_name,
        requesterName: candidate.app_metadata.requester,
        requesterEmail: user?.email ?? '',
        testId: candidate.app_metadata.test_id,
        testName: candidate.app_metadata.test_name,
        recipientEmail: candidate.email,
      });
    }
  };

  const handleInviteDialogClose = () => {
    setInviteUser(null);
  };
  const handleInviteSuccess = (message: string) => {
    setInviteUser(null);
    handleSuccess(message);
  };
  const handleInvitationOpen = () => {
    setLink(true);
  };
  const handleInvitationClose = () => {
    setLink(false);
  };

  const launchTest = () => {
    handleLaunchTest(testId);
  };

  const descriptionWithHash = setUserHashInAssessmentDescription(
    testId,
    descriptionLong || '',
    userHash
  );

  return (
    <Grid item xs={12} lg={9} md={9} sm={9} sx={{ padding: '1.5rem 0' }}>
      <Box
        sx={{
          display: 'flex',
          mb: '1rem',
          [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
          },
          [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
          },
        }}
      >
        {showCreateLinkDialog && (
          <CreateInvitationLinkDialog
            propTestId={testId}
            handleClose={handleCreateLinkDialogClose}
            onSuccess={handleIvitationSuccess}
            onEditSuccess={handleEditSuccess}
            onError={handleLinkError}
          />
        )}
        {showInviteCandidate && (
          <Box sx={{ maxWidth: 'fit-content', margin: '0 auto' }}>
            <CreateDialog
              propTestId={testId}
              handleClose={handleCreateDialogClose}
              onSuccess={handleCreateDialogSuccess}
              onError={handleError}
            />
          </Box>
        )}
        {showSnackbar && (
          <Snackbar
            open
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Alert
              variant="filled"
              onClose={handleSnackbarClose}
              severity={severity}
            >
              {message}
            </Alert>
          </Snackbar>
        )}
        {!!inviteUser && (
          <InviteDialog
            user={inviteUser}
            onClose={handleInviteDialogClose}
            onSuccess={handleInviteSuccess}
            onError={handleError}
          />
        )}
        {link && (
          <InvitationLink
            inviteURL={InvitationLinkURL}
            onClose={handleInvitationClose}
          />
        )}
        {hasNoAccess() && !isPersonal(user) ? (
          <Tooltip title="Subscribe now for an enhanced experience">
            <span>
              <Link to={`/company/settings/${ESettingsTabs.subscription}`}>
                <IconButton color="success" sx={styleIcon} disabled={true}>
                  <PersonAddAlt1 />
                </IconButton>
              </Link>
            </span>
          </Tooltip>
        ) : !isPersonal(user) ? (
          <Tooltip title="Invite a candidate to take this test">
            <IconButton
              id="test-catalog-invite-candidate"
              color="success"
              sx={styleIcon}
              onClick={handleInviteCandidate}
            >
              <PersonAddAlt1 />
            </IconButton>
          </Tooltip>
        ) : null}
        {canAccessProFeatures() && !isPersonal(user) && (
          <Tooltip title="Generate shareable invitation link">
            <IconButton
              id="test-catalog-create-invitation-link"
              color="success"
              sx={styleIcon}
              onClick={handleNewInvitationLinkDialog}
            >
              <AddLink />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Card
        sx={{ p: '1.2rem 0.3rem' }}
        id={`assessment-${testId}-short-description`}
      >
        <CardHeader
          title={
            title?.split(':')[1] === ' '
              ? title?.split(':')[0]
              : title?.split(':')[1]
          }
        />
        <CardContent sx={{ padding: '8px 24px' }}>
          <Typography
            variant="body2"
            display="block"
            color="text.primary"
            gutterBottom
            component="div"
            lineHeight={1.7}
            sx={{
              fontSize: '1rem',
            }}
          >
            {description && description}
          </Typography>
        </CardContent>
      </Card>
      <br />
      <Card
        id={`assessment-${testId}-learning-resources`}
        sx={{ p: '1.2rem 0.3rem' }}
      >
        <CardHeader
          title={'Learning Resources'}
          subheader={
            ' Here are learning resources that will help you understand the topics covered in this test'
          }
        />
        <CardContent>
          {!testResources?.length ? (
            <Typography variant="subtitle1" sx={{ p: 2, textAlign: 'center' }}>
              No Resources Available
            </Typography>
          ) : (
            <LearningResourcesView
              resources={testResources as Test_Tech_Services_Resources[]}
            />
          )}
        </CardContent>
      </Card>
      <br />
      <Card
        id={`assessment-${testId}-full-description`}
        sx={{ p: '1.2rem 0.3rem' }}
      >
        <CardHeader title={'Description'} />
        <CardContent>
          <Typography
            variant="body2"
            display="block"
            color="text.primary"
            gutterBottom
            component="div"
            lineHeight={1.7}
            sx={{
              fontSize: '1rem',
            }}
          >
            {descriptionWithHash &&
              CourseDescriptionHTML({
                description: descriptionWithHash,
              })}
          </Typography>
        </CardContent>
      </Card>
      <br />
      {!hideSandboxComponents && (
        <Card
          id={`assessment-${testId}-arch-diagram`}
          sx={{ p: '1.2rem 0.3rem' }}
        >
          <CardHeader title={'Architecture Diagram'} />
          <CardContent>
            {image ? (
              <Box
                component="img"
                src={image}
                alt="Challenge Diagram"
                sx={{
                  maxHeight: '100%',
                  maxWidth: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <Typography variant="body1">
                We will add a diagram soon for better experience
              </Typography>
            )}
          </CardContent>
        </Card>
      )}
      <Grid container justifyContent="flex-end" sx={{ mt: 2.5 }}>
        <Button
          id="test-catalog-launch-test-button-bottom"
          variant="contained"
          disabled={hasNoAccess()}
          startIcon={<RocketLaunch />}
          onClick={launchTest}
        >
          Launch Test
        </Button>
      </Grid>
    </Grid>
  );
};

const CourseDescriptionHTML: FC<{ description: string }> = ({
  description,
}) => {
  return <div dangerouslySetInnerHTML={{ __html: description }} />;
};

export default CourseDescription;
