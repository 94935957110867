import { axiosInstance } from '../../../utils/axios';

interface INewTrialProps {
  assessment_link_id: string | null;
  id: number;
  passed: null;
  previously_invited: true | false;
  requester: { company: { name: string } };
  started_at: null;
  submitted: null;
  submitted_at: null;
  test_trial_issues: [];
}

export interface ICurrentProgress {
  fully_completed: boolean;
  tasks_completed: number;
  total_tasks: number;
}

export const createTrialForUser = async (
  accessToken: string,
  {
    userhash,
    test_id,
    company_id,
    requester_id,
    re_invitation_allowed,
    available_issues,
    issues_per_test,
  }: {
    userhash: string;
    test_id: string;
    company_id: string;
    requester_id: string;
    re_invitation_allowed: boolean;
    available_issues: number;
    issues_per_test: number;
  }
): Promise<INewTrialProps> => {
  const { data } = await axiosInstance.postForm(
    '/api/results',
    {
      userhash,
      test_id,
      company_id,
      requester_id,
      re_invitation_allowed,
      available_issues,
      issues_per_test,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const checkSolution = async (
  accessToken: string,
  {
    fetch_details,
    test_id,
    test_slug,
    userhash,
  }: {
    fetch_details: boolean;
    test_id: number;
    test_slug: string;
    userhash: string;
  }
): Promise<ICurrentProgress> => {
  const { data } = await axiosInstance.get('/api/results/check-result', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      fetch_details,
      test_id,
      test_slug,
      userhash,
    },
  });

  return {
    fully_completed: Boolean(data.fully_completed),
    tasks_completed: Number(data.tasks_completed),
    total_tasks: Number(data.total_tasks),
  };
};

export const submitAssessment = async (
  accessToken: string,
  {
    userhash,
    test_id,
    trial_id,
  }: {
    userhash: string;
    test_id: number;
    trial_id: number | null;
  }
): Promise<void> => {
  const { data } = await axiosInstance.postForm(
    '/api/results/slack',
    {
      userhash,
      test_id,
      trial_id,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};
