export const MAX_TERMINAL_TABS = 3;
export const TERMINAL_HEADER_COLOR = 'neutral.900';

export const EAssessmentDifficulty = {
  Easy: 1,
  Medium: 2,
  Hard: 3,
};

export enum EEnvironmentType {
  CONSOLE = 'console',
  TERMINAL = 'terminal',
  CODING = 'coding',
}
export enum EAssessmentType {
  SANDBOX = 'Sandbox',
}

export enum EAssessmentCategoryType {
  AWS = 'AWS',
  Azure = 'Azure',
  GCP = 'GCP',
  TERRAFORM = 'Terraform',
}

export const CloudConsoleLoginUrls = {
  AWS: `https://{{awsAccountId}}.signin.aws.amazon.com/console`,
  Azure: `https://login.microsoftonline.com/aaa68647-c5e9-476d-9458-da3bf8be4abb/oauth2/v2.0/authorize?scope=openid&client_id=c44b4083-3bb0-49c1-b47d-974e53cbdf3c&login_hint={{loginHint}}&redirect_uri=https://portal.azure.com&error_uri=https://portal.azure.com`,
  GCP: `https://auth.cloud.google/signin/locations/global/workforcePools/auth0-brokee/providers/auth0?continueUrl=https://console.cloud.google/home/dashboard?project={{projectId}}`,
};

export const EnvironmentServiceUrls: Record<string, string> = {
  terminal: '/terminal/{{userHash}}-{{assessmentID}}?arg={{terminalSecret}}',
  coding: '/code-server/{{userHash}}-{{assessmentID}}/?arg={{terminalSecret}}',
};

export enum EAssessmentErrors {
  CHECKING_SOLUTION = 'You have reached your request limit. Please try again later.',
  INFRA_CREATING = 'Sometimes infrastructure creation may fail, please try again and if it still fails, please send a message in a chatbot below, our team most likely got notified about this already.',
  SUBMIT_ASSESSMENT = 'We encountered an issue while submitting your assessment. Retry submitting your assessment or reach out to our team in a chatbot below for assistance.',
}

export enum EEnvironmentStatuses {
  CREATING = 'Infrastructure is currently being set up. Please wait until it is fully established.',
  NONEXISTENT = 'There is no available infrastructure yet. Begin the assessment to start creating a new one.',
  NO_SUMMARY = 'No summary available for the infrastructure.',
}

export enum EInfraStatuses {
  IN_PROGRESS = 'in-progress',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}
